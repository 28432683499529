var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_vm._v(" REVENDAS ")]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisa","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)],1),_c('v-divider',{attrs:{"dark":""}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","sort-by":"Ordenar por","headers":_vm.headers,"items":_vm.resales,"items-per-page":10,"loading":_vm.loading,"search":_vm.search,"loading-text":"Carregando... Por favor aguarde","header-props":{
      sortByText: 'Ordenar por',
    },"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-minus',
      nextIcon: 'mdi-plus',
      itemsPerPageAllText: 'Todos',
      'items-per-page-text': 'Usuários por pagina',
    }},scopedSlots:_vm._u([{key:"no-results",fn:function(){return [_vm._v(" Nenhuma revenda encontrado ")]},proxy:true},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Lista de usuários")]),_c('v-spacer'),(!_vm.$vuetify.breakpoint.xs)?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":"","to":"/revenda/adicionar"}},[_vm._v(" Nova revenda ")]):_vm._e()],1)]},proxy:true},{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" de "+_vm._s(items.itemsLength)+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.edit(item.id)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)}),(_vm.$vuetify.breakpoint.xs)?_c('v-btn',{attrs:{"fab":"","color":"primary","bottom":"","fixed":"","right":"","to":"/revenda/adicionar"}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e(),_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"500","persistent":""},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Deseja realmente excluir a revenda "),_c('br'),_vm._v(" "+_vm._s(_vm.resaleDelete.name)+"?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","color":"error"},on:{"click":_vm.closeDelete}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.remove}},[_vm._v("Confirmar")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }