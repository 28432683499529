<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-row align="center">
          <v-col cols="12" md="6"> REVENDAS </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisa"
              single-line
              hide-details
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
    <v-divider dark></v-divider>
    <v-data-table
      dense
      sort-by="Ordenar por"
      :headers="headers"
      :items="resales"
      :items-per-page="10"
      class="elevation-1"
      :loading="loading"
      :search="search"
      loading-text="Carregando... Por favor aguarde"
      :header-props="{
        sortByText: 'Ordenar por',
      }"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
        itemsPerPageAllText: 'Todos',
        'items-per-page-text': 'Usuários por pagina',
      }"
    >
      <template v-slot:no-results> Nenhuma revenda encontrado </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Lista de usuários</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            to="/revenda/adicionar"
            v-if="!$vuetify.breakpoint.xs"
          >
            Nova revenda
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="edit(item.id)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <v-btn
      fab
      color="primary"
      bottom
      fixed
      right
      v-if="$vuetify.breakpoint.xs"
      to="/revenda/adicionar"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-dialog
      v-model="dialogDelete"
      transition="dialog-top-transition"
      max-width="500"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5"
          >Deseja realmente excluir a revenda <br>
          {{ resaleDelete.name }}?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="error" @click="closeDelete">Cancelar</v-btn>
          <v-btn depressed color="primary" @click="remove">Confirmar</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { baseApiUrl } from "@/global";
import axios from "axios";
export default {
  name: "Resale",
  data() {
    return {
      resales: [],
      loading: false,
      dialogDelete: false,
      resaleDelete: {},
      search: "",
      headers: [
        { text: "#", value: "id" },
        { text: "Nome", align: "start", value: "name" },
        { text: "CNPJ", value: "cnpj", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    getResales() {
      this.loading = true;
      const url = `${baseApiUrl}/resales`;
      axios.get(url).then((res) => {
        this.resales = res.data;
        this.loading = false;
      });
    },
    edit(id) {
      this.$router.push(`/revenda/${id}`);
    },
    remove() {
      axios
        .delete(`${baseApiUrl}/resale/${this.resaleDelete.id}`)
        .then(() => {
          this.closeDelete();
          this.getResales();
        })
        .catch((error) => alert(error));
    },
    deleteItem(item) {
      this.resaleDelete = item;
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
  },
  mounted() {
    this.getResales();
  },
};
</script>
<style>
@media (max-width: 599px) {
  .v-data-footer__select {
    margin-left: 0 !important;
    margin-right: 50px !important;
  }
  .v-data-footer__pagination {
    margin-left: 30px !important;
  }
}
</style>
